import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"

import Layer1 from '../images/bg-1.png';
import Layer2 from '../images/bg-2.png';
import Layer3 from '../images/bg-3.png';
import Logo from '../images/logo.png';


const Slider = () => {

    gsap.registerPlugin(ScrollTrigger)
    gsap.core.globals("ScrollTrigger", ScrollTrigger)

    useLayoutEffect(() => {
        const tl = gsap.timeline({

            scrollTrigger: {
                trigger: "#home",
                start: "top top",
                end: "bottom top",
                scrub: true,
            }

        });

        gsap.utils.toArray(".parallax").forEach(layer => {
            const depth = layer.dataset.depth;
            const movement = -(layer.offsetHeight * depth)
            tl.to(layer, { y: movement, ease: "none" }, 0)
        });

        return () => tl.scrollTrigger.kill()
    }, []);

    return (
        <>
            <div className="md:min-h-screen h-96 w-full bg-cover mx-auto overflow-hidden" id="home" >
                <img src={Layer1} className="fixed top-0 layer parallax w-full md:h-full h-96 " data-depth='0.10' />
                <img src={Logo} className="fixed right-0 left-0 md:top-32 top-20 layer parallax w-1/6 mx-auto" data-depth='0.60' />
                <img src={Layer2} className="fixed top-0 layer parallax w-full md:h-full h-96 " data-depth='0.25' />
                <img src={Layer3} className="fixed top-0 layer parallax w-full md:h-full h-96 " data-depth='0.40' />
            </div>
        </>
    )
}

export default Slider