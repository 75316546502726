
import React from "react"
import PropTypes from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"
import { useIntl } from "gatsby-plugin-intl"
import Seo from '../components/seo'
import "../styles/styles.css"
import Navigation from '../components/navigation'
// import Footer from "./footer"

const Layout = ({ children }) => {
    const intl = useIntl()

    return (
        <>
            <Seo
                lang={intl.locale}
                title={intl.formatMessage({ id: "pages.index" })}
                keywords={[`developer`, `front-end developer`, `graphic designer`]}
            />
            <div className="bg-midle-blue-green h-screen">
                <main role="main">{children}</main>
                {/* <Footer /> */}
            </div>

        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)
