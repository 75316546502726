import React from "react"

import Layout from "../components/layout"
import Navigation from "../components/navigation"
import Project from "../components/project"
import Slider from "../components/slider"
import logo from "../images/logo.gif"

const IndexPage = () => {


  return (
    <Layout>
      {/* <Navigation /> */}
      <Slider />
      <Project />
    </Layout >
  )
}

export default IndexPage
