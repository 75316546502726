import React, { useLayoutEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useIntl } from "gatsby-plugin-intl";

import asset from "../images/asset-01.gif";
import roadmap from "../images/roadmap.png";
import rmp1 from "../images/01-q4.png";
import rmp2 from "../images/02-q1.png";
import rmp3 from "../images/03-q2.png";
import rmp4 from "../images/04-q3.png";
import rmp5 from "../images/05-q4.png";
import rmp6 from "../images/06-q1.png";

const Project = () => {
    const intl = useIntl()
    gsap.registerPlugin(ScrollTrigger)
    gsap.core.globals("ScrollTrigger", ScrollTrigger)

    useLayoutEffect(() => {
        const tl = gsap.timeline({

            scrollTrigger: {
                trigger: "#home",
                start: "top top",
                end: "bottom top",
                scrub: true,
            }

        });

        gsap.utils.toArray(".parallax").forEach(layer => {
            const depth = layer.dataset.depth;
            const movement = -(layer.offsetHeight * depth)
            tl.to(layer, { y: movement, ease: "none" }, 0)
        });

        return () => tl.scrollTrigger.kill()
    }, []);

    return (
        <>
            <div className="bg-gradient-to-b from-midle-blue-green to-black relative min-h-screen">
                <div className="w-full container mx-auto py-20" >
                    <div id="roadmap" className='md:block hidden' >
                        <img src={roadmap} className="" />
                    </div>
                    <div className='md:hidden block'>
                        <img src={rmp1} className="w-full h-full" />
                        <img src={rmp2} className="w-full h-full" />
                        <img src={rmp3} className="w-full h-full" />
                        <img src={rmp4} className="w-full h-full" />
                        <img src={rmp5} className="w-full h-full" />
                        <img src={rmp6} className="w-full h-full" />
                    </div>
                </div>
            </div>
            <div className="bg-black relative min-h-screen">
                <div className="w-full container mx-auto py-20" >
                </div>
            </div>
        </>
    )
}

export default Project;